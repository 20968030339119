import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

import MarkdownViewer from "@components/MarkdownViewer"

const Careers = ({ data, pageContext, location }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const language = pageContext.language

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
        />

        <div className="careers-page">
          <div className="columns has-text-centered heading-section">
            <div className="column is-3"></div>
            <div className="column">
              <h1>{post.heading}</h1>

              {post.blurb && <MarkdownViewer markdown={post.blurb} />}
            </div>
            <div className="column is-3"></div>
          </div>

          <div className="columns">
            <div className="column is-3"></div>
            <div className="column">
              {post.jobs.map((job) => (
                <div className="careers__card" key={job.title}>
                  <h3>{job.title}</h3>
                  <MarkdownViewer markdown={job.description} />
                  <a
                    className="standard-button contained"
                    href={`mailto:${post.contactEmail}?subject=${job.title} Application`}
                  >
                    Apply
                  </a>
                </div>
              ))}
            </div>
            <div className="column is-3"></div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query CareersPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
        blurb
        contactEmail
        jobs {
          title
          description
        }
      }
    }
  }
`

export default Careers
